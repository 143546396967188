<script lang="ts">
	import { onMount } from 'svelte';
	import { preferencesStorage } from '../stores/preferences';

	export let article: any;
	export let isProdDetail = false;
	export let isHeader = false;
	export let fixedCurrency: string | undefined = undefined;

	onMount(() => {});

	function convertIfNeeded(czkAmount: number): number {
		if (fixedCurrency != null) {
			if (fixedCurrency === 'EUR') {
				const exchangeRate = 25.5;
				let price = czkAmount / exchangeRate;
				return Number.parseFloat(price.toFixed(1));
			} else {
				return czkAmount;
			}
		}

		if ($preferencesStorage.currency === 'EUR') {
			const exchangeRate = 25.5;
			let price = czkAmount / exchangeRate;
			return Number.parseFloat(price.toFixed(1));
		} else {
			return czkAmount;
		}
	}
</script>

{#if isHeader}
	{#if article != 0}
		{convertIfNeeded(article)}
		{#if fixedCurrency != null}
			{#if fixedCurrency === 'EUR'}
				EUR
			{:else}
				Kč
			{/if}
		{:else}
			{$preferencesStorage.currency}
		{/if}
	{:else}
		<span class="font-bold text-green">Zdarma</span>
	{/if}
{:else}
	<span
		class:text-2xl={isProdDetail}
		class:text-lg={!isProdDetail}
		class:text-center={!isProdDetail}
		class="font-bold"
		class:text-red={article?.price_before_discount != null}
	>
		{#if article?.price_before_discount != null}
			<span class="mr-1.5 font-normal text-gray-oslogray line-through">
				{convertIfNeeded(article?.price_before_discount)}&nbsp;{$preferencesStorage.currency}
			</span>
		{/if}

		{#if article?.min_price === article?.max_price}
			{convertIfNeeded(article?.price)}&nbsp;{$preferencesStorage.currency}
		{:else}
			{convertIfNeeded(article?.min_price)} - {convertIfNeeded(
				article?.max_price
			)}&nbsp;{$preferencesStorage.currency}
		{/if}
	</span>
{/if}
